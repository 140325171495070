import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BaseComponent} from "./core/base.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

  language = super.accessLanguage.length === 0 ? 'pt-BR' : super.accessLanguage;

  constructor(public router: Router,
              public translate: TranslateService
  ) {
    super(router, translate);
    translate.addLangs(['en-US', 'es-ES', 'pt-BR']);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.toggleLoader(false);
    }, 700);
  }

}
